/**
 * Created by katarinababic on 7.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import { Recipe } from '../../../Model/Diet/Recipe/Recipe';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { StringUtils } from '../../../Utils/StringUtils';

const Subtitle = styled.h2`
  padding-bottom: 16px;
  padding-top: 32px;
`;

const IngredientName = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

const IngredientAmount = styled.span`
  font-size: 20px;
`;

export type ExploreContentViewRecipeIngredientsProps = {
  entry: Recipe;
};

export const ExploreContentViewRecipeIngredients: React.FC<ExploreContentViewRecipeIngredientsProps> = observer(
  ({ entry }) => {
    const { language } = useRootStore();
    const { t } = useTrans();

    return (
      <Row>
        <Subtitle>{t('explore.dietTab.recipeScreen.ingredients', { defaultValue: 'Ingredients' })}</Subtitle>
        {entry.ingredients.map((ingredient) => {
          return (
            <Row key={ingredient.id}>
              <Col>
                <IngredientName>{StringUtils.capitalize(`• ${ingredient.getName(language.language)}`)}</IngredientName>
              </Col>
              <Col>
                <IngredientAmount>{ingredient.getDescription(language.language)}</IngredientAmount>
              </Col>
            </Row>
          );
        })}
      </Row>
    );
  },
);
