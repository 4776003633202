import { observable } from 'mobx';
import { RecipeEquipment, RecipeEquipmentJson } from './RecipeEquipment';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { StringUtils } from '../../../Utils/StringUtils';

/**
 * Created by neo on 19.12.20.
 */

export type RecipeInstructionStepJson = {
  number: number;
  step: LocalizedValueJson[];
  ingredients: string[];
  equipment: RecipeEquipmentJson[];
};

export class RecipeInstructionStep {
  @observable
  number: number = 0;
  @observable
  step: LocalizedValue[] = [];
  @observable
  ingredients: string[] = [];
  @observable
  equipment: RecipeEquipment[] = [];

  constructor(json?: Partial<RecipeInstructionStepJson>) {
    if (json) {
      this.number = json.number ?? 0;
      this.step = (json.step ?? []).map((l) => new LocalizedValue(l));
      this.ingredients = json.ingredients ?? [];
      this.equipment = (json.equipment ?? []).map((e) => new RecipeEquipment(e));
    }
  }

  getStepText(language: string = 'de') {
    const text =
      this.step.find((n) => n.lang === language)?.value ??
      this.step.find((n) => n.lang === 'en')?.value ??
      (this.step.length > 0 ? this.step[0]?.value : '');
    return StringUtils.capitalize(text || '', false);
  }
}
