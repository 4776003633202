/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { RecipeFilterItem, RecipeFilterItemProps } from './RecipeFilterItem';
import { useTrans } from '../../Store/System/LocalizationStore';
import { RecipeSearchParams } from '../../Model/Diet/Recipe/RecipeSearchParams';

export type RecipeFilterItemMinMaxProps = Omit<RecipeFilterItemProps, 'text'> & {
  valueKey: any;
  type: keyof RecipeSearchParams;
  minType: string;
  maxType: string;
  filter: RecipeSearchParams;
  onFilterPress?: (filters: RecipeSearchParams) => void;
};

export const RecipeFilterItemMinMax: React.FC<RecipeFilterItemMinMaxProps> = observer(
  ({ valueKey, type, minType, maxType, filter, onFilterPress }) => {
    const { t } = useTrans();
    const filterValue = filter[type];
    const isSelected = Number(filterValue) === valueKey;

    const handlePress = React.useCallback(() => {
      onFilterPress &&
        onFilterPress(
          Object.assign(
            {},
            filter,
            { [minType]: undefined, [maxType]: undefined },
            {
              [type]: isSelected ? undefined : valueKey,
            },
          ),
        );
    }, [onFilterPress, filter, minType, maxType, type, isSelected, valueKey]);

    return (
      <RecipeFilterItem
        text={t(`explore.dietTab.searchParams.${type}.${valueKey}`)}
        isSelected={isSelected}
        onPress={handlePress}
      />
    );
  },
);
