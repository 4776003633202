/**
 * Created by katarinababic on 7.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { RecipeInstruction } from '../../../Model/Diet/Recipe/RecipeInstruction';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { useRootStore } from '../../../Store/useRootStore';
import { Row } from 'reactstrap';

const Title = styled.span`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const Description = styled.span`
  font-size: 20px;
`;

export type ExploreContentViewRecipeInstructionsStepProps = {
  instruction: RecipeInstruction;
};

export const ExploreContentViewRecipeInstructionsStep: React.FC<ExploreContentViewRecipeInstructionsStepProps> =
  observer(({ instruction }) => {
    const { t } = useTrans();
    const { language } = useRootStore();

    return (
      <React.Fragment>
        {instruction.steps.map((step, index) => (
          <Row key={index}>
            <Title>{t('explore.dietTab.recipeScreen.stepValue', { value: index + 1 })}</Title>
            <Description>{step.getStepText(language.language)}</Description>
          </Row>
        ))}
      </React.Fragment>
    );
  });
