/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import styled from '@emotion/styled';
import { PageResult } from '../../Model/PageResult';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../Model/Explore/ExploreContentBuilder';
import { ExploreContentSwitch } from '../Academy/ExploreContentSwitch';
import { MoveLibrarySection } from './MoveLibrarySection';
import { Translation } from '../../Components/Translation';
import { ExploreCategoryContainer } from '../Academy/ExploreCategoryRenderer';

const ExploreContentContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 600px) {
    margin-bottom: 32px;
  }
`;

const MoveScreenContainer = styled.div``;

const TitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.2rem;
`;

export type MoveIndexScreenProps = {};

export const MoveIndexScreen: React.FC<MoveIndexScreenProps> = observer((props) => {
  const [content, setContent] = useState<PageResult<ExploreContent>>(new PageResult());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      ExploreContentBuilder.find({ categories: ['movement'] }),
      ExploreContentBuilder.count({ categories: ['movement'] }),
      page,
      20,
    ).then((res) => setContent(res));
  }, [page]);

  return (
    <MoveScreenContainer>
      <TitleContainer>
        <Container>
          <Title>
            <Translation
              i18nKey="moveOnDemand.title"
              defaultValue={`<span style="color: #24dca4">Previous</span> trainings`}
            />
          </Title>
          <Text>
            <Translation
              i18nKey="moveOnDemand.description"
              defaultValue={`<b>Missed a training session or want to revisit your favorite one?</b>\nNo worries! Our on-demand trainings allow you to train whenever and wherever it suits you. Get started now!`}
            />
          </Text>
        </Container>
      </TitleContainer>
      <ExploreContentContainer>
        {content.content.map((entry, index) => (
          <ExploreContentSwitch key={entry.id} content={entry} linkPrefix="/move/" />
        ))}
      </ExploreContentContainer>
      <ExploreCategoryContainer isLast={true}>
        <MoveLibrarySection />
      </ExploreCategoryContainer>
    </MoveScreenContainer>
  );
});
