/**
 * Created by katarinababic on 21.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Media } from '../../../Model/Media/Media';
import { KinasticAudioPlayer } from '../KinasticAudioPlayer';

const Container = styled.div`
  @media (min-width: 600px) {
    height: 360px;
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }
`;

export type ExploreContentViewMeditationPlayerProps = {
  audioTracks: Media[];
  backgroundImage?: string;
};

export const ExploreContentViewMeditationPlayer: React.FC<ExploreContentViewMeditationPlayerProps> = observer(
  ({ audioTracks, backgroundImage }) => {
    return (
      <Container>
        <KinasticAudioPlayer audioTracks={audioTracks} backgroundImage={backgroundImage} />
      </Container>
    );
  },
);
