/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '../../Theme/Theme';

const TagButton = styled.button<{ isSelected?: boolean }>`
  background-color: ${({ isSelected }) => (isSelected ? Theme.Colors.primary : '#f6f6f7')};
  border-radius: 32px;
  border-width: 0;
  margin: 8px 8px 8px 0;
  padding: 8px 16px;
`;

const TagText = styled.span<{ selected?: boolean }>`
  color: ${Theme.Colors.heavyWarmestGrey};
  font-weight: 600;
`;

export type RecipeFilterItemProps = {
  text: string;
  isSelected?: boolean;
  onPress?: () => void;
};

export const RecipeFilterItem: React.FC<RecipeFilterItemProps> = observer(({ text, isSelected, onPress }) => {
  const handlePress = React.useCallback(() => {
    onPress?.();
  }, [onPress]);

  return (
    <TagButton onClick={handlePress} isSelected={isSelected}>
      <TagText>{text}</TagText>
    </TagButton>
  );
});
