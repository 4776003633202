/**
 * Created by neo on 19.12.20.
 */
import { RecipeInstructionStep, RecipeInstructionStepJson } from './RecipeInstructionStep';
import { observable } from 'mobx';
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';

export type RecipeInstructionJson = {
  name: LocalizedValueJson[];
  steps: RecipeInstructionStepJson[];
};

export class RecipeInstruction {
  @observable
  name: LocalizedValue[] = [];
  @observable
  steps: RecipeInstructionStep[] = [];

  constructor(json?: Partial<RecipeInstructionJson>) {
    if (json) {
      this.name = (json.name ?? []).map((l) => new LocalizedValue(l));
      this.steps = (json.steps ?? []).map((s) => new RecipeInstructionStep(s));
    }
  }
}
