/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';
import { ExploreEntryContainer } from './ExploreEntryContainer';

export type ExploreEntryRendererRecipeProps = {
  entry: Recipe;
};

export const ExploreEntryRendererRecipe: React.FC<ExploreEntryRendererRecipeProps> = observer(
  ({ entry, ...moreProps }) => {
    return <ExploreEntryContainer entry={entry} {...moreProps} />;
  },
);
