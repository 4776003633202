/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { ArticleEntry } from '../../Model/Explore/ArticleEntry';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { ExploreContentViewOnlineMeeting } from './ExploreContentViewOnlineMeeting/ExploreContentViewOnlineMeeting';
import { ExploreContentViewArticle } from './ExploreContentViewArticle/ExploreContentViewArticle';
import { VideoEntry } from '../../Model/Explore/VideoEntry';
import { ExploreContentViewVideo } from './ExploreContentViewVideo/ExploreContentViewVideo';
import { ActivityWorkoutEntry } from '../../Model/Explore/ActivityWorkoutEntry';
import { ExploreContentViewActivityWorkout } from './ExploreContentViewActivityWorkout/ExploreContentViewActivityWorkout';
import { WebinarEntry } from '../../Model/Explore/WebinarEntry';
import { ExploreContentViewWebinar } from './ExploreContentViewWebinar/ExploreContentViewWebinar';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';
import { ExploreContentViewRecipe } from './ExploreContentViewRecipe/ExploreContentViewRecipe';
import { MeditationEntry } from '../../Model/Explore/MeditationEntry';
import { ExploreContentViewMeditation } from './ExploreContentViewMeditation/ExploreContentViewMeditation';
import { BreathingEntry } from '../../Model/Explore/BreathingEntry';
import { ExploreContentViewBreathing } from './ExploreContentViewBreathing/ExploreContentViewBreathing';

export type ExploreContentViewRendererProps = {
  entry: ExploreEntry | Recipe;
};

export const ExploreContentViewRenderer: React.FC<ExploreContentViewRendererProps> = observer(({ entry }) => {
  if (entry instanceof OnlineMeetingEntry) {
    return <ExploreContentViewOnlineMeeting entry={entry} />;
  } else if (entry instanceof ArticleEntry) {
    return <ExploreContentViewArticle entry={entry} />;
  } else if (entry instanceof VideoEntry) {
    return <ExploreContentViewVideo entry={entry} />;
  } else if (entry instanceof MeditationEntry) {
    return <ExploreContentViewMeditation entry={entry} />;
  } else if (entry instanceof ActivityWorkoutEntry) {
    return <ExploreContentViewActivityWorkout entry={entry} />;
  } else if (entry instanceof WebinarEntry) {
    return <ExploreContentViewWebinar entry={entry} />;
  } else if (entry instanceof Recipe) {
    return <ExploreContentViewRecipe entry={entry} />;
  } else if (entry instanceof BreathingEntry) {
    return <ExploreContentViewBreathing entry={entry} />;
  }

  return null;
});
