/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRootStore } from '../../Store/useRootStore';
import { useParams } from 'react-router-dom';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';
import { ExploreContentViewRenderer } from '../../Components/EntryView/ExploreContentViewRenderer';

export type NutritionContentViewScreenProps = {};

export const NutritionContentViewScreen: React.FC<NutritionContentViewScreenProps> = observer((props) => {
  const { analytics } = useRootStore();

  const [recipe, setRecipe] = useState<Recipe | undefined>();
  const { contentId } = useParams<{ contentId: string }>();

  useEffect(() => {
    setRecipe(undefined);
    if (contentId) {
      Recipe.get(contentId).then((res) => setRecipe(res));
    }
  }, [contentId]);

  useEffect(() => {
    if (recipe) {
      analytics.logSelectContent('recipe', recipe.id);
    }
  }, [analytics, recipe]);

  if (recipe) {
    return <ExploreContentViewRenderer entry={recipe} />;
  }
  return null;
});
