/**
 * Created by neo on 19.12.20.
 */
import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { v4 as UUID } from 'uuid';
import { observable } from 'mobx';
import { Media, MediaJson } from '../../Media/Media';

export type DietEquipmentJson = {
  id: string;
  spoonacularId: number;
  name: LocalizedValueJson[];
  image?: MediaJson;
};

export class DietEquipment {
  @observable
  id = UUID();
  @observable
  spoonacularId = 0;
  @observable
  name: LocalizedValue[] = [];
  @observable
  image?: Media;

  constructor(json?: Partial<DietEquipmentJson>) {
    if (json) {
      this.id = json.id ?? UUID();
      this.spoonacularId = json.spoonacularId ?? 0;
      this.name = (json.name ?? []).map((n) => new LocalizedValue(n));
      this.image = json.image ? new Media(json.image) : undefined;
    }
  }

  toJS(): DietEquipmentJson {
    return {
      id: this.id,
      spoonacularId: this.spoonacularId,
      name: this.name.map((l) => l.toJS()),
      image: this.image?.toJS(),
    };
  }
}
