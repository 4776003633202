/**
 * Created by neo on 29.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { useRootStore } from '../../Store/useRootStore';
import styled from '@emotion/styled';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';

const Description = styled.p`
  color: black;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 1 line */
  -webkit-box-orient: vertical;
  flex-shrink: 0; /* Prevent shrinking */
`;

export type ExploreCardShortDescriptionProps = {
  entry: ExploreEntry | Recipe;
};

export const ExploreCardShortDescription: React.FC<ExploreCardShortDescriptionProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const longDescription = entry.getDescription(language.language).substring(0, 64);
  const shortDescription =
    entry instanceof Recipe
      ? `${Math.floor(entry.nutrition?.nutrients.calories?.amount || 0)} kcal`
      : entry.getShortDescription(language.language);
  const description = shortDescription || `${longDescription}...`;

  if (shortDescription || longDescription) {
    return <Description>{description}</Description>;
  }

  return null;
});
