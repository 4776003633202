/**
 * Created by katarinababic on 21.11.24.
 */
import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Media } from '../../Model/Media/Media';
import { useRootStore } from '../../Store/useRootStore';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

const PlayerContainer = styled.div<{ backgroundImage?: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : 'none')};
  background-size: cover;
  background-position: center;
  overflow: hidden;
`;

const AudioPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const ControlsOverlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
`;

const ProgressBarContainer = styled.div`
  flex: 1;
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const ProgressBar = styled.input`
  width: 100%;
  height: 5px;
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

const TimeDisplay = styled.div`
  font-size: 12px;
  color: white;
  width: 80px;
  text-align: center;
`;

const IconButton = styled.i`
  font-size: 20px;
  color: white;
  cursor: pointer;
`;

const VolumeControlContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VolumeSlider = styled.input`
  width: 70px;
  height: 5px;
  appearance: none;
  background: #ccc;
  border-radius: 3px;
  cursor: pointer;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }
`;

export type KinasticAudioPlayerProps = {
  audioTracks: Media[];
  backgroundImage?: string;
};

export const KinasticAudioPlayer: React.FC<KinasticAudioPlayerProps> = observer(({ audioTracks, backgroundImage }) => {
  const { language } = useRootStore();
  const audioRef = useRef<ReactPlayer>(null);
  const playerContainerRef = useRef<HTMLDivElement>(null);

  const [isControlsVisible, setControlsVisible] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const audioMedia =
    audioTracks.find((a) => a.language === language.language) ??
    audioTracks.find((a) => a.language === 'en') ??
    audioTracks[0];

  const handleMouseEnter = React.useCallback(() => setControlsVisible(true), []);
  const handleMouseLeave = React.useCallback(() => setControlsVisible(false), []);

  const handleProgress = React.useCallback((state: { played: number }) => setProgress(state.played * 100), []);

  const handleDuration = React.useCallback((duration: number) => setDuration(duration), []);

  const handleSeek = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const seekTo = (parseFloat(event.target.value) / 100) * duration;
      audioRef.current?.seekTo(seekTo, 'seconds');
    },
    [duration],
  );

  const formatTime = React.useCallback((seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  }, []);

  const togglePlaying = React.useCallback(() => setIsPlaying((prevPlaying) => !prevPlaying), []);

  const toggleMute = React.useCallback(() => setIsMuted((prevMuted) => !prevMuted), []);

  const handleVolumeChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  }, []);

  const toggleFullscreen = React.useCallback(() => {
    if (!document.fullscreenElement) {
      playerContainerRef.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }, []);

  React.useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return (
    <PlayerContainer
      ref={playerContainerRef}
      backgroundImage={backgroundImage}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={togglePlaying}
    >
      <AudioPlayer
        ref={audioRef}
        playsinline={true}
        url={audioMedia?.largeOrMediumOrSmallest}
        width="100%"
        height="100%"
        muted={isMuted}
        volume={volume}
        controls={false}
        playing={isPlaying}
        loop={false}
        onProgress={handleProgress}
        onDuration={handleDuration}
      />
      <ControlsOverlay isVisible={isControlsVisible} onClick={(e) => e.stopPropagation()}>
        <IconButton onClick={togglePlaying} className={isPlaying ? 'bi bi-pause-fill' : 'bi bi-play-fill'} />
        <TimeDisplay>
          {formatTime((progress / 100) * duration)} / {formatTime(duration)}
        </TimeDisplay>
        <ProgressBarContainer>
          <ProgressBar type="range" value={progress} onInput={handleSeek} max="100" />
        </ProgressBarContainer>
        <VolumeControlContainer>
          <IconButton onClick={toggleMute} className={isMuted ? 'bi bi-volume-mute-fill' : 'bi bi-volume-up-fill'} />
          <VolumeSlider type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
        </VolumeControlContainer>
        <IconButton
          onClick={toggleFullscreen}
          className={isFullscreen ? 'bi bi-fullscreen-exit' : 'bi bi-fullscreen'}
          style={{ marginLeft: 8 }}
        />
      </ControlsOverlay>
    </PlayerContainer>
  );
});
