/**
 * Created by katarinababic on 22.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { BreathingSession } from '../../../Model/Explore/BreathingSession';
import { BreathingSoundsAndAnimations } from './BreathingSoundsAndAnimations';
import { Button } from '../../Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
`;

const StopButton = styled(Button)`
  margin-top: 32px;
`;

export type BreathingExerciseProgressProps = {
  activeSession: BreathingSession;
  onStart?: () => void;
  onStop?: () => void;
};

export const BreathingExerciseProgress: React.FC<BreathingExerciseProgressProps> = observer(
  ({ activeSession, onStart, onStop }) => {
    const animatedSequence = activeSession.entry.technique.map((t) => ({
      type: t.type,
      duration: t.duration,
    }));

    const growTime = animatedSequence.find((t) => t.type === 'inhale')?.duration || 0;
    const holdTime = animatedSequence.find((t) => t.type === 'hold')?.duration || 0;
    const shrinkTime = animatedSequence.find((t) => t.type === 'exhale')?.duration || 0;

    const image = (activeSession.entry.backgroundImage ?? activeSession.entry.image)?.largeOrMediumOrSmallestUriObject
      ?.uri;

    const handleStopBreathingSession = React.useCallback(() => {
      activeSession.endSession();
      const backgroundSound = new Audio(activeSession.selectedSound?.source());
      backgroundSound.pause();
      onStop?.();

      const activityLog = activeSession.createActivityLog();
      // todo do something with this activityLog
    }, [activeSession, onStop]);

    return (
      <Container>
        <BreathingSoundsAndAnimations
          isActive={activeSession.active}
          growTime={growTime}
          shrinkTime={shrinkTime}
          holdTime={holdTime}
          onStart={onStart}
          cycles={activeSession.totalCycles}
          backgroundImage={image}
        />
        {activeSession.active && <StopButton onClick={handleStopBreathingSession} value={'Stop breathing session'} />}
      </Container>
    );
  },
);
