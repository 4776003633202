/**
 * Created by neo on 22.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { OnlineMeetingEntry } from '../../Model/Explore/OnlineMeetingEntry';
import { ExploreEntryRendererOnlineMeeting } from './ExploreEntryRendererOnlineMeeting';
import { ArticleEntry } from '../../Model/Explore/ArticleEntry';
import { ExploreEntryRendererArticle } from './ExploreEntryRendererArticle';
import { VideoEntry } from '../../Model/Explore/VideoEntry';
import { ExploreEntryRendererVideo } from './ExploreEntryRendererVideo';
import { ActivityWorkoutEntry } from '../../Model/Explore/ActivityWorkoutEntry';
import { ExploreEntryRendererActivityWorkout } from './ExploreEntryRendererActivityWorkout';
import { ExploreEntryContainerProps } from './ExploreEntryContainer';
import { WebinarEntry } from '../../Model/Explore/WebinarEntry';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';
import { ExploreEntryRendererRecipe } from './ExploreEntryRendererRecipe';
import { MeditationEntry } from '../../Model/Explore/MeditationEntry';
import { BreathingEntry } from '../../Model/Explore/BreathingEntry';
import { ExploreEntryRendererBreathing } from './ExploreEntryRendererBreathing';

export type ExploreCardEntryRendererProps = Omit<ExploreEntryContainerProps, 'entry'> & {
  entry: ExploreEntry | Recipe;
};

export const ExploreCardEntryRenderer: React.FC<ExploreCardEntryRendererProps> = observer(({ entry, ...moreProps }) => {
  if (entry instanceof OnlineMeetingEntry) {
    return <ExploreEntryRendererOnlineMeeting entry={entry} {...moreProps} />;
  } else if (entry instanceof ArticleEntry) {
    return <ExploreEntryRendererArticle entry={entry} {...moreProps} />;
  } else if (entry instanceof VideoEntry) {
    return <ExploreEntryRendererVideo entry={entry} {...moreProps} />;
  } else if (entry instanceof ActivityWorkoutEntry) {
    return <ExploreEntryRendererActivityWorkout entry={entry} {...moreProps} />;
  } else if (entry instanceof WebinarEntry) {
    return <ExploreEntryRendererOnlineMeeting entry={entry} {...moreProps} />;
  } else if (entry instanceof Recipe) {
    return <ExploreEntryRendererRecipe entry={entry} {...moreProps} />;
  } else if (entry instanceof BreathingEntry) {
    return <ExploreEntryRendererBreathing entry={entry} {...moreProps} />;
  }

  return null;
});
