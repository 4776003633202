// @flow

export class StringUtils {
  static createPad(padString: string, count: number): string {
    if (count > 1) {
      let str = '';
      for (let i = 1; i < count; i += 1) {
        str += padString;
      }
      return str;
    }
    return padString;
  }

  static pad(input: string, padString: string = '0', count: number = 2): string {
    const end = count - input.length;
    if (end > 0) {
      const pad = this.createPad(padString, end);
      return pad + input;
    }
    return input;
  }

  static truncateString(str, num): string {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
  }

  static capitalize(input: string, lowerRestOfInput?: boolean): string {
    if (input && input.length > 0) {
      const tempInput = lowerRestOfInput ? input.toLowerCase() : input;
      return tempInput.charAt(0).toUpperCase() + tempInput.slice(1);
    }
    return input;
  }

  static nullOrEmpty(input?: string): boolean {
    if (input) {
      return input.trim().length === 0;
    }
    return true;
  }

  static stripAccepts(input?: string): string | undefined {
    if (input) {
      return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    return input;
  }

  static clean(input?: string, minTokenLength: number = 1): string | undefined {
    const clean = this.stripAccepts(input);
    if (clean) {
      return clean
        .trim()
        .toLowerCase()
        .replace(/\[/g, '')
        .replace(/-/g, '')
        .replace(/'/g, ' ')
        .replace(/`/g, ' ')
        .replace(/,/g, ' ')
        .replace(/;/g, ' ')
        .replace(/\//g, ' ')
        .replace(/]/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\^/g, '')
        .replace(/$/g, '')
        .replace(/\./g, '')
        .replace(/\\/g, ' ')
        .replace(/\?/g, '')
        .replace(/\+/g, '')
        .replace(/\|/g, '')
        .replace(/\{/g, '')
        .replace(/\}/g, '')
        .trim()
        .split(' ')
        .filter((s) => s.length > minTokenLength)
        .join(' ')
        .trim();
    }
    return input;
  }
}
