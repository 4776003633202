/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../Store/useRootStore';
import styled from '@emotion/styled';
import { MarkdownRenderer } from '../../Markdown/MarkdownRenderer';
import { ActivityWorkoutEntry } from '../../../Model/Explore/ActivityWorkoutEntry';
import { MeditationEntry } from '../../../Model/Explore/MeditationEntry';

const Title = styled.h1``;

export type ExploreContentViewActivityWorkoutContentProps = {
  entry: ActivityWorkoutEntry;
};

export const ExploreContentViewActivityWorkoutContent: React.FC<ExploreContentViewActivityWorkoutContentProps> =
  observer(({ entry }) => {
    const { language } = useRootStore();

    const title = entry.getName(language.language);
    const content = entry.getDescription(language.language);
    const quote = entry.quotes.find((n) => n.lang === language.language.toLowerCase())?.value;

    return (
      <React.Fragment>
        <Title>{title}</Title>
        <MarkdownRenderer text={content} />
        {entry instanceof MeditationEntry && quote ? <MarkdownRenderer text={quote} /> : null}
      </React.Fragment>
    );
  });
