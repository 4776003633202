/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { PageResult } from '../../Model/PageResult';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { ExploreContentBuilder } from '../../Model/Explore/ExploreContentBuilder';
import styled from '@emotion/styled';
import { Container } from 'reactstrap';
import { Translation } from '../../Components/Translation';
import { ExploreContentSwitch } from '../Academy/ExploreContentSwitch';
import { ExploreCategoryContainer } from '../Academy/ExploreCategoryRenderer';
import { MentalHealthLibrarySection } from './MentalHealthLibrarySection';

const ExploreContentContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 600px) {
    margin-bottom: 32px;
  }
`;

const TitleContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 16px;
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.2rem;
`;

export type MentalHealthIndexScreenProps = {};

export const MentalHealthIndexScreen: React.FC<MentalHealthIndexScreenProps> = observer((props) => {
  const [content, setContent] = useState<PageResult<ExploreContent>>(new PageResult());
  const [page, setPage] = useState(0);

  useEffect(() => {
    PageResult.execute(
      ExploreContentBuilder.find({ categories: ['mental'] }),
      ExploreContentBuilder.count({ categories: ['mental'] }),
      page,
      20,
    ).then((res) => setContent(res));
  }, [page]);

  return (
    <div>
      <TitleContainer>
        <Container>
          <Title>
            <Translation
              i18nKey="mentalHealthScreen.title"
              defaultValue={`<span style="color: #24dca4">Our</span> mental health content`}
            />
          </Title>
          <Text>
            <Translation
              i18nKey="mentalHealthScreen.description"
              defaultValue={
                'Enhance your well-being with our mental health resources, including webinars, meditations, and breathing exercises. Access practical strategies anytime, anywhere.'
              }
            />
          </Text>
        </Container>
      </TitleContainer>
      <ExploreContentContainer>
        {content.content.map((entry, index) => (
          <ExploreContentSwitch key={entry.id} content={entry} linkPrefix="/mental-health/" />
        ))}
      </ExploreContentContainer>
      <ExploreCategoryContainer isLast={true}>
        <MentalHealthLibrarySection />
      </ExploreCategoryContainer>
    </div>
  );
});
