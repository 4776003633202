/**
 * Created by katarinababic on 7.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Recipe } from '../../../Model/Diet/Recipe/Recipe';
import { Row } from 'reactstrap';
import { ExploreContentViewRecipeInstructionsStep } from './ExploreContentViewRecipeInstructionsStep';
import styled from '@emotion/styled';
import { useTrans } from '../../../Store/System/LocalizationStore';

const Subtitle = styled.h2`
  padding-bottom: 16px;
  padding-top: 32px;
`;

export type ExploreContentViewRecipeInstructionsProps = {
  entry: Recipe;
};

export const ExploreContentViewRecipeInstructions: React.FC<ExploreContentViewRecipeInstructionsProps> = observer(
  ({ entry }) => {
    const { t } = useTrans();

    return (
      <Row>
        <Subtitle>
          {t('explore.dietTab.recipeScreen.cookingInstructions', { defaultValue: 'Cooking instructions' })}
        </Subtitle>
        {entry.instructions.map((instruction, index) => (
          <ExploreContentViewRecipeInstructionsStep key={index} instruction={instruction} />
        ))}
      </Row>
    );
  },
);
