/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../Store/useRootStore';
import { Recipe } from '../../../Model/Diet/Recipe/Recipe';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Theme } from '../../../Theme/Theme';
import { ExploreContentViewRecipeNutrients } from './ExploreContentViewRecipeNutrients';
import { ExploreContentViewRecipeIngredients } from './ExploreContentViewRecipeIngredients';
import { ExploreContentViewRecipeInstructions } from './ExploreContentViewRecipeInstructions';

const MediaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-size: cover !important;
  background-position: center 50% !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;

  @media (min-width: 600px) {
    height: 360px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 560px;
  }
`;

const TitleContainer = styled.div``;

const Title = styled.h1`
  margin-top: 32px;
`;

const Icon = styled.i`
  font-size: 32px;
  color: ${Theme.Colors.primary};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

const IconText = styled.span`
  margin-left: 12px;
  font-size: 18px;
  font-weight: 500;
`;

const MiddleContainer = styled(Row)`
  padding-top: 40px;
`;

const IngredientsContainer = styled(Col)`
  border-right: 1px solid #e5e5e5;
  padding-bottom: 80px;
`;

const NutrientsContainer = styled(Col)`
  padding-left: 80px;
  padding-bottom: 80px;
`;

const RecipeSource = styled.p`
  margin-top: 32px;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
`;

export type ExploreContentViewRecipeProps = {
  entry: Recipe;
};

export const ExploreContentViewRecipe: React.FC<ExploreContentViewRecipeProps> = observer(({ entry }) => {
  const { language } = useRootStore();
  const { t } = useTrans();

  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;
  const recipeSource = entry.sourceName ?? entry.creditsText ?? 'kinastic';
  const calories = Math.floor(entry.nutrition?.nutrients.calories?.amount || 0);
  const duration = Math.floor(entry.readyInMinutes || 0);
  const servings = Math.floor(entry.servings || 0);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Row>
            <MediaContainer style={{ background: `url('${image}')` }} />
            <TitleContainer>
              <Title>{entry.getName(language.language)}</Title>
              <Row>
                <Col xs={2}>
                  <IconContainer>
                    <Icon className="bi bi-alarm" />
                    <IconText>{`${duration} ${t('globals.time.minutes')}`}</IconText>
                  </IconContainer>
                </Col>
                <Col xs={2}>
                  <IconContainer>
                    <Icon className="bi bi-fire" />
                    <IconText>{`${calories} kcal ${t('explore.dietTab.recipeScreen.perServing')}`}</IconText>
                  </IconContainer>
                </Col>
                <Col xs={2}>
                  <IconContainer>
                    <Icon className="bi bi-cup-hot" />
                    <IconText>{t('explore.dietTab.recipeScreen.servings', { value: servings })}</IconText>
                  </IconContainer>
                </Col>
              </Row>
            </TitleContainer>
          </Row>
        </Col>
      </Row>
      <MiddleContainer>
        <IngredientsContainer xs={6}>
          <ExploreContentViewRecipeIngredients entry={entry} />
          <ExploreContentViewRecipeNutrients entry={entry} />
        </IngredientsContainer>
        <NutrientsContainer xs={6}>
          <ExploreContentViewRecipeInstructions entry={entry} />
        </NutrientsContainer>
      </MiddleContainer>
      <RecipeSource>
        {t('explore.dietTab.recipeScreen.source', { defaultValue: 'by {{value}}', value: recipeSource })}
      </RecipeSource>
    </Container>
  );
});
