/**
 * Created by katarinababic on 25.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Col, Container, Row } from 'reactstrap';
import { useTrans } from '../../Store/System/LocalizationStore';
import { PageResult } from '../../Model/PageResult';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { ExploreEntryBuilder } from '../../Model/Explore/ExploreEntryBuilder';
import { Translation } from '../../Components/Translation';
import { ExploreFilterTagsSection } from '../Academy/ExploreFilterTagsSection';
import { ExploreCardEntryRenderer } from '../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { Button } from '../../Components/Button';

const Header = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
`;

const Description = styled.p`
  margin-top: 16px;
`;

const FiltersTagsContainer = styled(Row)`
  padding-bottom: 32px;
`;

export type MentalHealthLibraryScreenProps = {};

export const MentalHealthLibraryScreen: React.FC<MentalHealthLibraryScreenProps> = observer((props) => {
  const { t } = useTrans();

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(new PageResult<ExploreEntry>());
  const [page, setPage] = useState(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  useEffect(() => {
    if (page === 0) {
      setResult(new PageResult());
    }
    setLoading(true);
    PageResult.execute(
      ExploreEntryBuilder.find({
        rootCategories: ['mental'],
        filterTags: selectedTags,
        type: ['video', 'article', 'activityWorkout', 'meditation', 'breathing'],
        size: 16,
        sort: 'createdAt,DESC',
        page,
      }),
      ExploreEntry.count({
        rootCategories: ['mental'],
        type: ['video', 'article', 'activityWorkout', 'meditation', 'breathing'],
      }),
      page,
      16,
    )
      .then((res) => setResult((prev) => prev.merge(res)))
      .finally(() => setLoading(false));
  }, [page, selectedTags]);

  const handleLoadMore = React.useCallback(() => {
    setPage((p) => p + 1);
  }, []);

  const handleSelectFilterTag = React.useCallback((tags: string[]) => {
    setSelectedTags(tags);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col xs={12}>
            <Header>
              <Translation
                i18nKey="mentalHealthLibrary.title"
                defaultValue={`On Demand\n<span style="color:#24dca4">Library</span>`}
              />
            </Header>
          </Col>
          <Col md={7}>
            <Description>
              <Translation
                i18nKey="mentalHealthLibrary.description"
                defaultValue={`Missed a live session? No worries. You can find a selection of all mental health contents in our on demand library.`}
              />
            </Description>
          </Col>
        </Row>
        <FiltersTagsContainer>
          <ExploreFilterTagsSection category={'mental'} selectedTags={selectedTags} onFilter={handleSelectFilterTag} />
        </FiltersTagsContainer>
        <Row>
          {result.content.map((entry) => (
            <ExploreCardEntryRenderer key={entry.id} entry={entry} linkPrefix={'/mental-health/'} />
          ))}
        </Row>
        {!result.last && (
          <Row>
            <Col style={{ textAlign: 'center', paddingTop: 32 }}>
              <Button color="link" disabled={loading} onClick={handleLoadMore}>
                {t('globals.loadMore', { defaultValue: 'Load More' })}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
});
