import __DEV__, { __BETA__ } from './Dev';

export const Constants: any = {
  goldenRation: 1.61803398875,
  invertGoldenRation: 0.6180339887,
  MAX_INT: 4294967296,
};

export const EMPTY_ARRAY = [];

export const EMPTY_OBJECT = {};

export const EMPTY_STRING = '';

export const APPLE_DOWNLOAD_LINK = 'https://apps.apple.com/us/app/kinastic-coach/id1465156373';

export const GOOGLE_DOWNLOAD_LINK = 'https://play.google.com/store/apps/details?id=app.flinq.athlete&hl=en&gl=US';

export const EMAIL_REGEX = /(?!.*\.\.)(^[^\\.][^@\s]+@[^@\s]+\.[^@\s\\.]{2,8}$)/;

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&\\.])[A-Za-z\d@$!%*#?&\\.]{8,}$/;

export const REGEX_UPPER_CASE = /[A-Z]+/;
export const REGEX_LOWER_CASE = /[a-z]+/;
export const REGEX_NUMBERS = /[0-9]+/;
export const REGEX_SPECIAL_CHARS = /[@$!%*#?&^()\\`\-_;:'".,~=+><±§[\]/{}]/;

export const HOURS_1 = 3600000;
export const HOURS_2 = 7200000;
export const HOURS_6 = 21600000;
export const HOURS_12 = 43200000;
export const HOURS_24 = 86400000;
export const HOURS_48 = 172800000;

export const SUPPORTED_TYPES = [
  'video',
  'article',
  'onlineMeeting',
  'activityWorkout',
  'yoga',
  'webinar',
  'meditation',
  'breathing',
];

export const APPLE_CLIENT_ID = 'com.kinastic.coach-web.signin';
export const APPLE_REDIRECT_URI = __DEV__
  ? 'https://dev.coach.kinastic.com:3000/auth'
  : __BETA__
  ? 'https://beta.coach.kinastic.com/auth'
  : 'https://coach.kinastic.com/auth';
