/**
 * Created by katarinababic on 7.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { Recipe } from '../../../Model/Diet/Recipe/Recipe';

const Subtitle = styled.h2`
  padding-bottom: 16px;
  padding-top: 32px;
`;

const NutrientName = styled.span`
  font-weight: 500;
  font-size: 20px;
`;

const NutrientAmount = styled.span`
  font-size: 20px;
`;

const Container = styled(Row)`
  margin-top: 80px;
`;

const nutrients: string[] = [
  'calories',
  'carbohydrates',
  'sugar',
  'fat',
  'saturated_fat',
  'sodium',
  'protein',
  'fiber',
];

export type ExploreContentViewRecipeNutrientsProps = {
  entry: Recipe;
};

export const ExploreContentViewRecipeNutrients: React.FC<ExploreContentViewRecipeNutrientsProps> = observer(
  ({ entry }) => {
    const { t } = useTrans();

    return (
      <Container>
        <Subtitle>
          {t('explore.dietTab.recipeScreen.nutritionInformation.title', { defaultValue: 'Nutrition information' })}
        </Subtitle>
        <Col>
          <Row>
            {nutrients.map((n) => {
              const nutrient = entry.nutrition?.nutrients[n];
              return (
                <Row key={n}>
                  <Col>
                    <NutrientName>{`• ${t(`explore.dietTab.recipeScreen.nutritionInformation.${n}`)}`}</NutrientName>
                  </Col>
                  <Col>
                    <NutrientAmount>{`${(nutrient?.amount || 0).toFixed(1)} ${nutrient?.unit || ''}`}</NutrientAmount>
                  </Col>
                </Row>
              );
            })}
          </Row>
        </Col>
      </Container>
    );
  },
);
