/**
 * Created by katarinababic on 21.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { MeditationEntry } from '../../../Model/Explore/MeditationEntry';
import { useRootStore } from '../../../Store/useRootStore';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewMeditationPlayer } from './ExploreContentViewMeditationPlayer';
import { ExploreContentViewActivityWorkoutContent } from '../ExploreContentViewActivityWorkout/ExploreContentViewActivityWorkoutContent';
import { ExploreContentViewHostCard } from '../ExploreContentViewHostCard';
import { LanguageDisclaimer } from '../LanguageDisclaimer';

export type ExploreContentViewMeditationProps = {
  entry: MeditationEntry;
};

export const ExploreContentViewMeditation: React.FC<ExploreContentViewMeditationProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const audioTracks = entry.narrators.map((n) => n.getAudioTrack(language.language));

  const backgroundImage = entry?.getImage(language.language)?.largeOrMediumOrSmallestUriObject.uri;
  const narrator = entry.narrators[0];

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <ExploreContentViewMeditationPlayer audioTracks={audioTracks} backgroundImage={backgroundImage} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={7} xl={9} style={{ paddingTop: 16 }}>
          <ExploreContentViewActivityWorkoutContent entry={entry} />
        </Col>
        <Col xs={12} md={5} xl={3}>
          <ExploreContentViewHostCard host={narrator} />
          {/*<LanguageDisclaimer availableLanguages={entry.availableLanguages} />*/}
        </Col>
      </Row>
    </Container>
  );
});
