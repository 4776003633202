/**
 * Created by neo on 19.12.20.
 */
import { Ingredient, IngredientJson } from '../Ingredient/Ingredient';
import { Measure, MeasureJson } from '../Measure';
import { observable } from 'mobx';

export type RecipeIngredientJson = IngredientJson & {
  measures: MeasureJson;
};

export class RecipeIngredient extends Ingredient {
  @observable
  measures: Measure = new Measure();

  constructor(json?: Partial<RecipeIngredientJson>) {
    super(json);
    if (json) {
      this.measures = new Measure(json.measures);
    }
  }

  toJS(): RecipeIngredientJson {
    return Object.assign(super.toJS(), {
      measures: this.measures.toJS(),
    });
  }

  getMeasurement(metric: boolean = true): string {
    return metric
      ? `${this.measures.metric.amount} ${this.measures.metric.unitShort}`
      : `${this.measures.us.amount} ${this.measures.us.unitShort}`;
  }
}
