/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Col, Container, Row } from 'reactstrap';
import styled from '@emotion/styled';
import { Translation } from '../../Components/Translation';
import { RecipeSearchParams } from '../../Model/Diet/Recipe/RecipeSearchParams';
import { Recipe } from '../../Model/Diet/Recipe/Recipe';
import { ExploreCardEntryRenderer } from '../../Components/ExploreEntryCard/ExploreCardEntryRenderer';
import { Button } from '../../Components/Button';
import { RecipePreparationFiltersSection } from './RecipePreparationFiltersSection';

const Header = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
`;

const FiltersContainer = styled(Row)`
  padding-bottom: 32px;
`;

const NoResultsText = styled.p`
  margin-top: 16px;
  text-align: center;
  font-size: 1.5rem;
`;

export type NutritionIndexScreenProps = {};

export const NutritionIndexScreen: React.FC<NutritionIndexScreenProps> = observer((props) => {
  const { t } = useTrans();

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);

  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [recipeFilter, setRecipeFilter] = useState<RecipeSearchParams>({});

  const hasMore = React.useRef(false);
  const showEmptyResultsView = recipes.length === 0 && !loading;

  const loadRecipes = React.useCallback(
    (params: RecipeSearchParams) => {
      if (page === 0) {
        setRecipes([]);
      }
      setLoading(true);
      Recipe.search(Object.assign({}, params ?? {}, { size: 32, page }))
        .then((res) => {
          setRecipes((prev) => prev.concat(res));
          hasMore.current = res.length >= 32;
        })
        .finally(() => setLoading(false));
    },
    [page],
  );

  useEffect(() => {
    const scrollPosition = localStorage.getItem('nutrition_scroll_position');

    if (scrollPosition && recipes.length > 0) {
      window.scrollTo(0, parseInt(scrollPosition));
      localStorage.removeItem('nutrition_scroll_position');
    }
  }, [recipes]);

  useEffect(() => {
    loadRecipes(Object.assign({}, recipeFilter));
  }, [loadRecipes, recipeFilter]);

  const handleLoadMore = React.useCallback(() => {
    setPage((p) => p + 1);
  }, []);

  const handleFilterPress = React.useCallback(
    (newFilter: RecipeSearchParams) => {
      setPage(0);
      setRecipeFilter(newFilter);
    },
    [setRecipeFilter],
  );

  const handleRecipeClick = React.useCallback(() => {
    localStorage.setItem('nutrition_scroll_position', window.scrollY.toString());
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Header>
            <Translation i18nKey="nutrition.title" defaultValue={`Our <span style="color:#24dca4">Recipes</span>`} />
          </Header>
        </Col>
      </Row>
      <FiltersContainer>
        <RecipePreparationFiltersSection onFilterPress={handleFilterPress} />
      </FiltersContainer>
      <Row>
        {recipes.map((entry) => (
          <ExploreCardEntryRenderer
            key={entry.id}
            entry={entry}
            linkPrefix={`/nutrition/`}
            onClick={handleRecipeClick}
          />
        ))}
      </Row>
      {hasMore && (
        <Row>
          <Col style={{ textAlign: 'center', paddingTop: 32 }}>
            <Button color="link" disabled={loading} onClick={handleLoadMore}>
              {t('globals.loadMore', { defaultValue: 'Load More' })}
            </Button>
          </Col>
        </Row>
      )}
      {showEmptyResultsView && (
        <NoResultsText>
          <Translation
            i18nKey="nutrition.noResults"
            defaultValue={'Unfortunately there are no results for the selected filter combination'}
          />
        </NoResultsText>
      )}
    </Container>
  );
});
