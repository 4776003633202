/**
 * Created by katarinababic on 25.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Theme } from '../../../Theme/Theme';
import { BreathingSession, MusicFile } from '../../../Model/Explore/BreathingSession';
import { useRootStore } from '../../../Store/useRootStore';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';
import { runInAction } from 'mobx';

const MenuItem = styled(DropdownItem)<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? Theme.Colors.lighterGrey : Theme.Colors.white)};
`;

export function useTimeoutSounds(): MusicFile[] {
  const { language } = useRootStore();

  const { t } = useTrans();

  return React.useMemo(
    () => [
      {
        title: t('explore.timeoutTab.noSound', { locale: language.language }),
        key: 'no-sound',
        source: () => undefined,
      },
      {
        title: 'Distant Thunder and Water Dripping from Forest Canopy',
        key: 'Distant-Thunder-and-Water-Dripping-from-Forest-Canopy.mp3',
        source: () =>
          require('../../../Assets/Audio/Breathing/Distant_Thunder_and_Water_Dripping_from_Forest_Canopy.mp3'),
      },
      {
        title: 'Exotic Swamp Birds',
        key: 'Exotic-Swamp-Birds.mp3',
        source: () => require('../../../Assets/Audio/Breathing/Exotic_Swamp_Birds.mp3'),
      },
      {
        title: 'Ocean Waves',
        key: 'Ocean-Waves_1.mp3',
        source: () => require('../../../Assets/Audio/Breathing/Ocean_Waves.mp3'),
      },
      {
        title: 'Light Rain Falling on Forest Floor',
        key: 'Light-Rain-Falling-on-Forest-Floor_1.mp3',
        source: () => require('../../../Assets/Audio/Breathing/Light_Rain_Falling_on_Forest_Floor.mp3'),
      },
      {
        title: 'Morning Bird Songs',
        key: 'Morning Bird Songs.mp3',
        source: () => require('../../../Assets/Audio/Breathing/Morning_Bird_Songs.mp3'),
      },
      {
        title: 'Mountain Atmosphere',
        key: 'Mountain-Atmosphere_1.mp3',
        source: () => require('../../../Assets/Audio/Breathing/Mountain_Atmosphere.mp3'),
      },
    ],
    [language.language, t],
  );
}

export type BreathingExerciseBackgroundSoundProps = {
  breathingSession: BreathingSession;
};

export const BreathingExerciseBackgroundSound: React.FC<BreathingExerciseBackgroundSoundProps> = observer(
  ({ breathingSession }) => {
    const { t } = useTrans();

    const soundSelection = useTimeoutSounds();

    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const handleSelectSound = React.useCallback(
      (m: MusicFile) => {
        runInAction(() => breathingSession.setSelectedSound(m));
      },
      [breathingSession],
    );

    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
        <DropdownToggle caret style={{ backgroundColor: Theme.Colors.primary, border: 'none' }}>
          {t('explore.breathing.chooseBackgroundAudio', { defaultValue: 'Choose background audio' })}
        </DropdownToggle>
        <DropdownMenu>
          {soundSelection.map((m) => (
            <MenuItem
              key={m.key}
              selected={breathingSession.selectedSound?.key === m.key}
              onClick={() => handleSelectSound(m)}
            >
              {m.title}
            </MenuItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  },
);
