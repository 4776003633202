/**
 * Created by katarinababic on 21.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { BreathingEntry } from '../../Model/Explore/BreathingEntry';
import { ExploreEntryContainer } from './ExploreEntryContainer';

const InnerContainer = styled.div`
  position: relative;
  height: 100%;
`;

const PlayOverlayContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const PlayIcon = styled.img`
  position: absolute;
  width: 50px;
`;

export type ExploreEntryRendererBreathingProps = {
  entry: BreathingEntry;
};

export const ExploreEntryRendererBreathing: React.FC<ExploreEntryRendererBreathingProps> = observer(
  ({ entry, ...moreProps }) => {
    return (
      <ExploreEntryContainer entry={entry} {...moreProps}>
        <InnerContainer>
          <PlayOverlayContainer>
            <PlayIcon src={require('../../Assets/Images/play_icon.png')} />
          </PlayOverlayContainer>
        </InnerContainer>
      </ExploreEntryContainer>
    );
  },
);
