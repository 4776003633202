/**
 * Created by katarinababic on 20.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useRootStore } from '../../Store/useRootStore';
import { ExploreContent } from '../../Model/Explore/ExploreContent';
import { useParams } from 'react-router-dom';
import { ExploreContentBuilder } from '../../Model/Explore/ExploreContentBuilder';
import { ExploreEntry } from '../../Model/Explore/ExploreEntry';
import { ExploreContentViewRenderer } from '../../Components/EntryView/ExploreContentViewRenderer';
import { RelatedEntriesView } from '../../Components/RelatedEntriesView';
import { ExploreCategory } from '../../Model/Explore/ExploreCategory';
import { Container } from 'reactstrap';
import { ExploreContentViewCategoryRenderer } from '../Academy/View/CategoryView/ExploreContentViewCategoryRenderer';

export type MentalHealthContentViewScreenProps = {};

export const MentalHealthContentViewScreen: React.FC<MentalHealthContentViewScreenProps> = observer((props) => {
  const { analytics } = useRootStore();

  const { contentId } = useParams<{ contentId: string }>();

  const [content, setContent] = useState<ExploreContent | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setContent(undefined);
    if (contentId) {
      setLoading(true);
      ExploreContentBuilder.findOne(contentId)
        .then((res) => setContent(res))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [contentId]);

  useEffect(() => {
    if (content) {
      analytics.logSelectContent(content.type, content.id);
    }
  }, [analytics, content]);

  if (content) {
    if (content instanceof ExploreEntry) {
      return (
        <React.Fragment>
          <ExploreContentViewRenderer entry={content} />
          <RelatedEntriesView entry={content} linkPrefix={'/mental-health/'} />
        </React.Fragment>
      );
    } else if (content instanceof ExploreCategory) {
      return (
        <Container>
          <ExploreContentViewCategoryRenderer category={content} linkPrefix={'/mental-health/'} />
        </Container>
      );
    }
  }

  return <Container>{content && <React.Fragment>Category</React.Fragment>}</Container>;
});
