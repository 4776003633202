/**
 * Created by katarinababic on 6.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styled from '@emotion/styled';
import { RecipeFilterItemMinMax } from './RecipeFilterItemMinMax';
import { RecipeSearchParams } from '../../Model/Diet/Recipe/RecipeSearchParams';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export type RecipePreparationFiltersSectionProps = {
  onFilterPress?: (filters: RecipeSearchParams) => void;
};

export const RecipePreparationFiltersSection: React.FC<RecipePreparationFiltersSectionProps> = observer(
  ({ onFilterPress }) => {
    const [recipeFilter, setRecipeFilter] = useState<RecipeSearchParams>({});

    const handleFilterPress = React.useCallback(
      (newFilter: RecipeSearchParams) => {
        setRecipeFilter(newFilter);
        onFilterPress?.(Object.assign({}, newFilter, { page: 0 }));
      },
      [onFilterPress],
    );

    return (
      <Container>
        <RecipeFilterItemMinMax
          valueKey={15}
          type="maxReadyTime"
          minType="minReadyTime"
          maxType="maxReadyTime"
          filter={recipeFilter}
          onFilterPress={handleFilterPress}
        />
        <RecipeFilterItemMinMax
          valueKey={30}
          type="maxReadyTime"
          minType="minReadyTime"
          maxType="maxReadyTime"
          filter={recipeFilter}
          onFilterPress={handleFilterPress}
        />
        <RecipeFilterItemMinMax
          valueKey={31}
          type="minReadyTime"
          minType="minReadyTime"
          maxType="maxReadyTime"
          filter={recipeFilter}
          onFilterPress={handleFilterPress}
        />
      </Container>
    );
  },
);
