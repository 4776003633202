/**
 * Created by neo on 18.12.20.
 */
import { observable } from 'mobx';

export type MeasureEntryJson = {
  amount: number;
  unitShort: string;
};

export class MeasureEntry {
  @observable
  amount = 0;
  @observable
  unitShort = '';

  constructor(json?: Partial<MeasureEntryJson>) {
    if (json) {
      this.amount = json.amount ?? 0;
      this.unitShort = json.unitShort ?? '';
    }
  }

  toJS(): MeasureEntryJson {
    return {
      amount: this.amount,
      unitShort: this.unitShort,
    };
  }
}
