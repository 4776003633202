/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { OnlineMeetingEntry } from '../../../Model/Explore/OnlineMeetingEntry';
import styled from '@emotion/styled';
import { useRootStore } from '../../../Store/useRootStore';
import ReactPlayer from 'react-player';
import { logger } from '../../../Utils/logger';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { VideoEntry } from '../../../Model/Explore/VideoEntry';
import { KinasticVideoPlayer } from '../KinasticVideoPlayer';
import { useTrans } from '../../../Store/System/LocalizationStore';

const Container = styled.div<{ backgroundImageUrl?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: ${({ backgroundImageUrl }) => (backgroundImageUrl ? `url('${backgroundImageUrl}')` : 'none')};
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: 16px;

  @media (min-width: 600px) {
    height: 360px;
    margin-bottom: 32px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }
`;

export type ExploreContentViewArticleTeaserProps = {
  entry: VideoEntry;
};

export const ExploreContentViewVideoPlayer: React.FC<ExploreContentViewArticleTeaserProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

  return (
    <Container backgroundImageUrl={image}>
      <KinasticVideoPlayer videos={entry.videos} showLanguageSelector={true} />
    </Container>
  );
});
