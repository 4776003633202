/**
 * Created by neo on 02.03.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Col, Container, Row } from 'reactstrap';
import { Translation } from '../../Components/Translation';
import { Button } from '../../Components/Button';
import { useTrans } from '../../Store/System/LocalizationStore';
import { Link } from 'react-router-dom';

const ContentContainer = styled.div`
  padding: 2rem 0;

  @media (min-width: 768px) {
    padding: 32px 0;
  }
`;

const ImageCol = styled(Col)`
  text-align: center;

  @media (min-width: 768px) {
    padding-left: 64px;
  }
`;

const TextCol = styled(Col)`
  @media (min-width: 768px) {
    padding-right: 64px;
  }
`;

const Image = styled.img`
  height: 300px;

  @media (min-width: 768px) {
    height: 400px;
  }
`;

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.2rem;
`;

export type OnDemandHeaderSectionProps = {};

export const MoveLibrarySection: React.FC<OnDemandHeaderSectionProps> = observer((props) => {
  const { t } = useTrans();

  return (
    <ContentContainer>
      <Container>
        <Row>
          <ImageCol>
            <Image src={require('../../Assets/Images/sport_ondemand_section.png')} />
          </ImageCol>
          <TextCol>
            <Title>
              <Translation
                i18nKey="moveScreen.onDemandSection.title"
                defaultValue={`Watch <span style="color: #24dca4">On demand</span> trainings`}
              />
            </Title>
            <Text>
              <Translation
                i18nKey="moveScreen.onDemandSection.description"
                defaultValue={`You want to workout but you missed the live class? No problem, we have trainings you can do at any time and place!`}
              />
            </Text>
            <Button tag={Link} to="library" shadowed={true}>
              {t('moveScreen.onDemandSection.goToLibraryButton', { defaultValue: 'GO TO LIBRARY' })}
            </Button>
          </TextCol>
        </Row>
      </Container>
    </ContentContainer>
  );
});
