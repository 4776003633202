/**
 * Created by neo on 18.12.20.
 */
import { MeasureEntry, MeasureEntryJson } from './MeasureEntry';
import { observable } from 'mobx';

export type MeasureJson = {
  us: MeasureEntryJson;
  metric: MeasureEntryJson;
};

export class Measure {
  @observable
  us: MeasureEntry = new MeasureEntry();
  @observable
  metric: MeasureEntry = new MeasureEntry();

  constructor(json?: Partial<MeasureJson>) {
    if (json) {
      this.us = new MeasureEntry(json.us);
      this.metric = new MeasureEntry(json.metric);
    }
  }

  toJS(): MeasureJson {
    return {
      us: this.us.toJS(),
      metric: this.metric.toJS(),
    };
  }
}
